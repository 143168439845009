import { FC, ReactNode, useEffect, useState } from 'react'
import {
  ClipboardIcon,
  ClipboardDocumentCheckIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline'
import { classNames } from '@g4g/utils/src/react/class-names'

const FEEDBACK_DURATION = 2000

export const useCopyToClipboard = (text: string) => {
  let feedbackTimeOut: ReturnType<typeof setTimeout>
  const [copied, setCopied] = useState<boolean>(false)
  const [error, setError] = useState<null | string>(null)
  const handleCopy = async (): Promise<void> => {
    if (!copied && text !== '') {
      try {
        await navigator.clipboard.writeText(text)
        setCopied(true)

        feedbackTimeOut = setTimeout(() => {
          setCopied(false)
        }, FEEDBACK_DURATION)
      } catch (error) {
        setError(error as string)
        feedbackTimeOut = setTimeout(() => {
          setError(null)
        }, FEEDBACK_DURATION)
      }
    }
  }

  useEffect(() => {
    return () => clearTimeout(feedbackTimeOut)
  }, [])

  return { handleCopy, copied, error }
}

export const CopyToClipboardText: FC<{
  text: string
  copied: boolean
  error: string | null
  textGrow?: boolean
  children?: ReactNode
  'data-cy'?: string
}> = ({ copied, error, textGrow, children, 'data-cy': dataCy, text }) => (
  <>
    {error ? (
      <span className={classNames('text-rose-500', textGrow && 'grow')}>Failed to copy</span>
    ) : (
      <>
        {copied ? (
          <span className={classNames('text-shopStdGreen', textGrow && 'grow')}>Copied</span>
        ) : (
          <span data-cy={dataCy} className={classNames(textGrow && 'grow')} title={text}>
            {children}
          </span>
        )}
      </>
    )}
  </>
)

const CopyToClipboard: FC<{
  text: string
  textGrow?: boolean
  'data-cy'?: string
  children?: ReactNode
}> = ({ text, children, textGrow, 'data-cy': dataCy }) => {
  const { copied, error, handleCopy } = useCopyToClipboard(text)

  return (
    <div className="flex items-center space-x-2 text-white font-poppins">
      <CopyToClipboardText
        text={text}
        copied={copied}
        error={error}
        textGrow={textGrow}
        data-cy={dataCy}
      >
        {children}
      </CopyToClipboardText>
      <div title="Copy to clipboard" className="flex" onClick={() => handleCopy()}>
        {error ? (
          <ExclamationCircleIcon className="w-5 h-5 text-rose-500" />
        ) : (
          <>
            {copied ? (
              <ClipboardDocumentCheckIcon className="w-5 h-5 text-shopStdGreen" />
            ) : (
              <ClipboardIcon className="w-5 h-5 cursor-pointer text-secondaryLight hover:text-white" />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default CopyToClipboard
