import { loadStoredTag, addTagToUrl } from '@g4g/utils/src/react/deploy-tag'
import CopyToClipboard from '../components/CopyToClipboard'

// Get the full url with the deploy tag, easy to link into jira cards.
const getUrlWithTag = () => {
  return addTagToUrl(window.location.href).href
}

const Tag = () => (
  <div
    title="To overwrite use ?tag=staging0 or overwrite Local storage"
    className="fixed font-bold top-0 left-0 inline-flex self-center px-2 text-xs text-white bg-black z-[10000]"
  >
    <CopyToClipboard text={getUrlWithTag()}>{loadStoredTag()}</CopyToClipboard>
  </div>
)

export default Tag
