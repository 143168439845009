import type { FC } from 'react'
import { __DEV__, __STAGING__ } from '@g4g/utils/src/react/environment'
import ClientOnly from './ClientOnly'
import DeployTagBody from './DeployTagBody'

const DeployTag: FC = () => (
  <>
    {(__DEV__ || __STAGING__) && (
      <ClientOnly>
        <DeployTagBody />
      </ClientOnly>
    )}
  </>
)

export default DeployTag
